import { createSlice, Draft } from '@reduxjs/toolkit';

import config from 'src/config';
import { SeatType, SeatTypeRelatedFeatures, seatTypeToFeaturesMapping } from 'src/config/seatTypeToFeatureToggles';

import {
  fetchAppInfo,
  fetchExtraInfo,
  fetchRestReasons,
  fetchSkillGroupList,
  loginInstance,
  updateAppInfo,
  updateExtraInfo,
  updatePhoneNumReflect,
} from './app.thunk';

interface RestReason {
  restId: string;
  name: string;
  state: string;
  sortId: string;
}

type TimeRange = {
  from: string;
  to: string;
};
export interface AppSettings {
  hideCalloutNumber: boolean;
  hideCallinNumber: boolean;
  mobileSwitchDisplay: boolean;
  staffAssignedCalloutNumber: boolean;
  callTimeout: number;
  imAutoAccept: boolean;
  imTimeout: number;
  audioTimeout: number;
  videoTimeout: number;
  endCountdown: number;
  imEndCountdown: number;
  audioEndCountdown: number;
  videoEndCountdown: number;
  telAutoAccept: boolean;
  telAutoAcceptRange: TimeRange;
  audioAutoAccept: boolean;
  videoAutoAccept: boolean;
  smsPrivilege: boolean;
  telCalloutPostIVR: boolean;
  telCallinPostIVR: boolean;
  loginStaffStatus: string;
  CRMMaximized: boolean;
  calloutPreferLocation: boolean;
  calloutPreferSkillGroup: boolean;
  skillGroupList: SkillGroup[];
  numberReflectMode: number;
  restReasonList: RestReason[];
  asr: boolean;
  callGiveupSetRest: boolean;
  imGiveupSetRest: boolean;
  audioGiveupSetRest: boolean;
  videoGiveupSetRest: boolean;
  seatType: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  isAdvanced: boolean;
  isNormal: boolean;
  ringingBeforeCalling: boolean;
  audioNotifyAfterHangup: boolean;
  realtimeAsr: boolean;
  autoRealtimeAsr: boolean;
  crmEnabled: boolean;
  crmGray: boolean;
  featureFlag: SeatTypeRelatedFeatures;
  telBlackUserEnable: boolean;
  imBlackUserEnable: boolean;
  imContactUserEnable: boolean;
  staffShowReadReceipt: number;
  // 如果seattype是4，imAppType为1则为新版智能客服
  imAppType: number;
  minify: boolean;
  hasUseMobileStaff: boolean;
}

const FULL_TIME = {
  from: '00:00',
  to: '23:59',
};

const initialState: AppSettings = {
  hideCalloutNumber: false,
  hideCallinNumber: false,
  calloutPreferLocation: false,
  calloutPreferSkillGroup: false,
  numberReflectMode: 0,
  mobileSwitchDisplay: false,
  callTimeout: 15,
  imAutoAccept: false,
  imTimeout: 15,
  audioTimeout: 15,
  videoTimeout: 15,
  endCountdown: 15,
  imEndCountdown: 15,
  audioEndCountdown: 15,
  videoEndCountdown: 15,
  telAutoAccept: false,
  staffAssignedCalloutNumber: false,
  telAutoAcceptRange: FULL_TIME,
  audioAutoAccept: false,
  videoAutoAccept: false,
  smsPrivilege: false,
  telCalloutPostIVR: false,
  telCallinPostIVR: false,
  loginStaffStatus: '100',
  CRMMaximized: false,
  skillGroupList: [] as SkillGroup[],
  restReasonList: [],
  asr: false,
  callGiveupSetRest: false,
  imGiveupSetRest: false,
  audioGiveupSetRest: false,
  videoGiveupSetRest: false,
  seatType: 2,
  isAdvanced: false,
  isNormal: true,
  ringingBeforeCalling: false,
  audioNotifyAfterHangup: false,
  realtimeAsr: false,
  autoRealtimeAsr: false,
  crmEnabled: false,
  crmGray: false,
  featureFlag: config.isDeskKit
    ? seatTypeToFeaturesMapping[SeatType.TUiDeskKit]
    : seatTypeToFeaturesMapping[SeatType.Standard],
  telBlackUserEnable: true,
  imBlackUserEnable: true,
  imContactUserEnable: false,
  staffShowReadReceipt: 0,
  imAppType: 0,
  minify: false,
  hasUseMobileStaff: false,
};

const updateAppSettingsReducer = (
  state: Draft<typeof initialState>,
  { payload }: { payload: Partial<typeof initialState> },
) => ({ ...state, ...payload });

const slices = createSlice({
  name: 'AppSettings',
  initialState,
  reducers: {
    updateAppSettings: updateAppSettingsReducer,

    updateCRMMaximized: (state, { payload }) => {
      state.CRMMaximized = payload;
    },

    updateMinify: (state, { payload }) => {
      state.minify = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppInfo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(fetchExtraInfo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(updateExtraInfo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(updateAppInfo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(updatePhoneNumReflect.fulfilled, (state, action) => {
        state.numberReflectMode = action.payload.numberReflectMode;
      })
      .addCase(loginInstance.fulfilled, (state, action) => ({
        ...state,
        ...action.payload.appSettings,
      }))
      .addCase(fetchRestReasons.fulfilled, (state, action) => ({ ...state, restReasonList: action.payload }))
      .addCase(fetchSkillGroupList.fulfilled, (state, { payload }) => {
        state.skillGroupList = payload as Array<SkillGroup>;
      });
  },
});
export const appSettings = slices.reducer;
export const { updateAppSettings, updateCRMMaximized, updateMinify } = slices.actions;
