import * as t from 'io-ts';

import services from 'src/services';
import { request } from 'src/services/httpClient';
import {
  getDecodedResultOrReportError,
  booleanFromNumber0or1,
  booleanFromString0or1,
  numberFromString,
} from 'src/utils/codecs';
const FULL_TIME = {
  from: '00:00',
  to: '23:59',
};
export const AppSettingsC = t.partial({
  hideCalloutNumber: booleanFromString0or1,
  hideCallinNumber: booleanFromString0or1,
  callTimeout: numberFromString,
  mobileSwitchDisplay: booleanFromString0or1,
  imTimeout: numberFromString,
  endCountdown: numberFromString,
  imEndCountdown: numberFromString,
  callOutNum: t.string,
  imAutoAccept: booleanFromString0or1,
  telAutoAccept: booleanFromString0or1,
  smsPrivilege: booleanFromString0or1,
  telCallinPostIVR: booleanFromString0or1,
  telCalloutPostIVR: booleanFromString0or1,
  loginStaffStatus: t.string,
  calloutPreferSkillGroup: t.boolean,
  calloutPreferLocation: t.boolean,
  numberReflectMode: numberFromString,
  asr: t.boolean,
  webGray: t.boolean,
  audioAutoAccept: t.boolean,
  audioEndCountdown: t.number,
  audioTimeout: t.number,
  videoAutoAccept: t.boolean,
  videoEndCountdown: t.number,
  videoTimeout: t.number,
  callGiveupSetRest: booleanFromNumber0or1,
  imGiveupSetRest: booleanFromNumber0or1,
  audioGiveupSetRest: booleanFromNumber0or1,
  videoGiveupSetRest: booleanFromNumber0or1,
  seatType: t.union([t.literal(1), t.literal(2), t.literal(3), t.literal(4), t.literal(5), t.literal(6), t.literal(7)]),
  ringingBeforeCalling: t.boolean,
  audioNotifyAfterHangup: t.boolean,
  realtimeAsr: t.boolean,
  crmEnabled: t.boolean,
  crmGray: t.boolean,
  hasUseMobileStaff: t.boolean,
});
type AppSettings = t.TypeOf<typeof AppSettingsC>;
export const getAppInfo = async (data: { sdkAppId?: string } = {}) => {
  const rawResponse = await services['/tcccadmin/app/getAppInfo'](data);
  // 只解析必要字段，其他字段删除
  delete rawResponse.errorCode;
  delete rawResponse.nonce;
  delete rawResponse.msg;
  localStorage.removeItem('TCCC_GREY_USER'); // 废弃字段
  return getDecodedResultOrReportError(AppSettingsC.decode(rawResponse));
};

// App级别的配置信息，如果后台不方便支持，前端暂时采用commonKV的方式来做
export const getExtraInfo = async () => {
  const key = 'telAutoAcceptRange';
  let obj = null;
  try {
    const response = await request('/tcccadmin/app/getCommKV', { key });
    obj = JSON.parse(response.value);
  } catch (error) {
    // 如果获取失败，为了兼容性考虑，回退为全时段自动接听
    obj = FULL_TIME;
  }
  return { telAutoAcceptRange: obj };
};

// App级别的配置信息，如果后台不方便支持，前端暂时采用commonKV的方式来做
export const updateExtraInfo = async (data: { value: any }) => {
  const key = 'telAutoAcceptRange';
  await request('/tcccadmin/app/setCommKV', { key, value: JSON.stringify(data.value) });
  return { telAutoAcceptRange: data.value };
};

export const setAppInfo = async (partial: Partial<AppSettings>) => {
  await services['/tcccadmin/app/setAppInfo'](AppSettingsC.encode(partial));
  return partial;
};
export const getRestReasons = async (data: { sdkAppId?: string } = {}) => {
  const { restReasonList } = await request('/tcccadmin/staff/getRestReasonList', data);
  return restReasonList;
};
