import * as t from 'io-ts';

import { IVR_STATUS, IVR_TYPE } from 'src/constants/manage';
import { booleanFromString0or1, createEnumType, nullableArray, numberFromString, stringEnum } from 'src/utils/codecs';

import { AppSettingsC } from './appInfo';
import { SelfServiceSource, SelfServiceStatus, SelfServiceType } from './types';

const PstnEndStatus = t.union([
  t.literal(0),
  t.literal(1),
  t.literal(2),
  t.literal(17),
  t.literal(100),
  t.literal(101),
  t.literal(102),
  t.literal(103),
  t.literal(104),
  t.literal(105),
  t.literal(106),
  t.literal(107),
  t.literal(108),
  t.literal(109),
  t.literal(110),
  t.literal(111),
  t.literal(201),
  t.literal(202),
  t.literal(203),
  t.literal(204),
  t.literal(205),
  t.literal(206),
  t.literal(207),
  t.literal(208),
  t.literal(209),
  t.literal(210),
  t.literal(211),
]);

const HungUpSideType = t.union([t.literal('seat'), t.literal('user')]);
const UseMobileAccept = t.union([t.literal(0), t.literal(1), t.literal(2)]);

export enum StaffStatus {
  Offline = '0',
  Free = '100',
  Busy = '200',
  Rest = '300',
  AfterWorkCountDown = '400',
  AfterWork = '500',
  NotReady = '700',
}
export enum BusinessScenario {
  undefined = 0,
  callOutbound = 1,
  callIn = 2,
  chatWeb = 3,
  audio = 4,
  video = 5,
}
export enum AccessMode {
  saas = 1,
  sdk = 2,
  notSure = 3,
}
export const booleanFromNumber1or2 = t.union([t.literal(1), t.literal(2)]).pipe(
  new t.Type<boolean, 1 | 2, 1 | 2>(
    'booleanFromNumber1or2',
    t.boolean.is,
    (i) => t.success(i === 1),
    (b) => (b ? 1 : 2),
  ),
);
export const StaffItem = t.type({
  roleId: t.string,
  userId: t.string,
  staffNo: t.string,
  staffName: t.string,
  nickName: t.string,
  skillGroupId: t.string,
  avatar: t.string,
  ctime: numberFromString,
  kickOff: booleanFromString0or1,
  mobile: t.string,
  mtime: numberFromString,
  sdkAppId: t.string,
  useMobile: t.string,
  wechatMiniProgramAccept: booleanFromString0or1,
  status: t.union([t.undefined, stringEnum(StaffStatus)]),
  priority: t.union([t.undefined, t.string]),
  relation: t.union([t.undefined, t.string]),
});

export type Staff = t.TypeOf<typeof StaffItem>;

export const NewStaff = t.type({
  roleId: t.string,
  staffUserId: t.string,
  staffNumber: t.string,
  staffName: t.string,
  staffNickName: t.string,
  skillGroupIds: t.array(t.string),
  avatar: t.string,
  ctime: numberFromString,
  kickOff: booleanFromString0or1,
  mobile: t.string,
  mtime: numberFromString,
  sdkAppId: t.string,
  useMobile: t.string,
  wechatMiniProgramAccept: booleanFromString0or1,
  status: t.union([t.undefined, stringEnum(StaffStatus)]),
  priority: t.string,
});

export const BlackUser = t.type({
  id: t.number,
  userType: t.number,
  mobile: t.string,
  openId: t.string,
  nickName: t.string,
  desc: t.string,
  servingNum: t.string,
  addUser: t.string,
  addTime: t.number,
  modifyTime: t.number,
  protectedMobile: t.string,
});

export type BlackUserType = t.TypeOf<typeof BlackUser>;

export const SkillGroupType = t.union([t.literal(0), t.literal(1), t.literal(2), t.literal(3), t.literal(4)]);

export const SupervisorPermissionGroupKeys = t.union([
  t.literal('TEL_BILL_STAT_DASHBOARD'),
  t.literal('SEAT_STAT_DASHBOARD'),
  t.literal('VIDEO_STAT_DASHBOARD'),
  t.literal('OVERVIEW'),
  t.literal('TEL_SEAT_STATS'),
  t.literal('IM_SEAT_STATS'),
  t.literal('RADIO_SEAT_STATS'),
  t.literal('VIDEO_SEAT_STATS'),
  t.literal('ALL_SEAT_STATS'),
  t.literal('SEAT_STATUS_TRACE_STATS'),
  t.literal('NUMBER_HEALTH_STATS'),
  t.literal('TEL_SEAT_CDR'),
  t.literal('IM_SEAT_SERVICE_LOG'),
  t.literal('RADIO_SEAT_SERVICE_LOG'),
  t.literal('VIDEO_SEAT_SERVICE_LOG'),
  t.literal('INTERNAL_LINE_SERVICE_LOG'),
  t.literal('SEAT_MOINTOR'),
  t.literal('SKILL_GROUP_MONITOR'),
  t.literal('REALTIME_CALL_MONITOR'),
]);
export const AdminPermissionGroupKeys = t.union([
  t.literal('IM_SKILL_GROUP_CONFIG'),
  t.literal('IM_SESSION_FLOW_CONFIG'),
  t.literal('IM_STATIS_CONFIG'),
  t.literal('IM_CHANNEL_CONFIG'),
  t.literal('IM_SEAT_CONFIG'),
  t.literal('IM_TEXT_ROBOT_CONFIG'),
  t.literal('TEL_SKILL_GROUP_CONFIG'),
  t.literal('TEL_IVR_CONFIG'),
  t.literal('TEL_STATIS_CONFIG'),
  t.literal('TEL_NUMBER_CONFIG'),
  t.literal('TEL_CALLOUT_TASK'),
  t.literal('TEL_SEAT_CONFIG'),
  t.literal('TEL_SELF_HELP_CONFIG'),
  t.literal('TEL_DTMF_CALLBACK_CONFIG'),
  t.literal('TEL_RADIO_FILE_CONFIG'),
  t.literal('TEL_QC_CONFIG'),
  t.literal('TEL_SIP_DEVICE_CONFIG'),
  t.literal('RADIO_SKILL_GROUP_CONFIG'),
  t.literal('RADIO_IVR_CONFIG'),
  t.literal('RADIO_SELF_HELP_CONFIG'),
  t.literal('RADIO_RADIO_FILE_CONFIG'),
  t.literal('RADIO_CHANNEL_CONFIG'),
  t.literal('RADIO_SEAT_CONFIG'),
  t.literal('VIDEO_SKILL_GROUP_CONFIG'),
  t.literal('VIDEO_CHANNEL_CONFIG'),
  t.literal('VIDEO_SEAT_CONFIG'),
  t.literal('CALL_SCREEN_CONFIG'),
  t.literal('QUICK_REPLY_CONFIG'),
  t.literal('BLACKLIST_CONFIG'),
  t.literal('BASIC_CONFIG'),
  t.literal('SEAT_CONFIG'),
  t.literal('CLIENT_CONFIG'),
  t.literal('CLIENT_INFO_CONFIG'),
  t.literal('PERMISSION_CONFIG'),
  t.literal('CRM_ALL'),
  t.literal('ASR_HOT_WORDS_CONFIG'),
]);
export const PermissionGroupKey = t.union([
  t.literal('*'),
  SupervisorPermissionGroupKeys,
  AdminPermissionGroupKeys,
  // API role
  t.literal('LEGANCY_REVIEWER'), // Supervisor,
  t.literal('LEGANCY_ADMIN'), // Admin,
]);

export type PermissionGroupKeyType = t.TypeOf<typeof PermissionGroupKey>;

export const AdminKeys: string[] = AdminPermissionGroupKeys.types.map((v) => v.value as string);
export const SuperVisorKeys: string[] = SupervisorPermissionGroupKeys.types.map((v) => v.value as string);

export const SkillGroupItem = t.intersection([
  t.type({
    skillGroupId: t.string,
    skillGroupName: t.string,
    ctime: t.string,
    mtime: t.string,
    maxConcurrency: numberFromString,
    extraFlag: t.union([t.literal('IM'), t.literal('TEL'), t.literal('ALL')]),
    skillGroupType: SkillGroupType,
    freeStaffCount: t.number,
    ringAll: t.boolean,
    StaffCount: t.number,
  }),
  t.partial({
    mobileStaffCount: t.number,
  }),
]);

export type SkillGroup = t.TypeOf<typeof SkillGroupItem>;

export const RoleItem = t.type({
  roleId: t.string,
  roleName: t.string,
});

export const CurveItem = t.type({
  time: t.string,
  value: t.number,
});

const NumberState = t.union([t.literal(0), t.literal(1), t.literal(2), t.literal(4), t.literal(5)]);

export type NumberStateType = t.TypeOf<typeof NumberState>;

export const TelNumberItem = t.type({
  assocIvrVersion: t.string,
  callinPostIVRId: t.string,
  calloutPostIVRId: t.string,
  ivrId: t.string,
  notWorkTimeVoice: t.string,
  servingNum: t.string,
  type: t.union([t.literal('1'), t.literal('2'), t.literal('3'), t.literal('4')]),
  workTimePeriod: t.string,
  workTimeType: t.string,
  remark: t.union([t.undefined, t.string]),
  maxCallCount: t.number,
  maxCallPsec: t.number,
});

export const IvrItem = t.type({
  ivrId: t.string,
  ivrName: t.string,
  ivrCreateType: t.string,
  mtime: t.string,
  pubState: stringEnum(IVR_STATUS),
  ivrJsonStr: t.string,
  type: stringEnum(IVR_TYPE),
  versionList: t.array(t.string),
  bindNumber: t.array(t.string),
  bindSelfServiceNames: t.union([t.undefined, t.array(t.string)]),
});

export const ExtensionItem = t.type({
  sdkAppId: t.string,
  extensionId: t.string,
  skillGroupId: t.string,
  fullExtensionId: t.string,
  relation: t.union([t.undefined, t.string]),
  relationName: t.union([t.undefined, t.string]),
  extensionName: t.string,
  ctime: numberFromString,
  mtime: numberFromString,
  priority: t.union([t.undefined, t.string]),
  register: t.union([t.undefined, t.boolean]),
  status: t.union([t.undefined, stringEnum(StaffStatus)]),
  userAgent: t.string,
  clientAddr: t.string,
  granted: t.boolean,
});

export const InnerNumberItem = t.type({
  alias: t.string,
  aliasType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
  origin: t.string,
  name: t.string,
  groupId: t.string,
  relation: t.string,
  relationName: t.string,
  status: t.number,
  register: t.boolean,
});

export type Ivr = t.TypeOf<typeof IvrItem>;

export type TelNumber = t.TypeOf<typeof TelNumberItem>;

export type Extension = t.TypeOf<typeof ExtensionItem>;
export type InnerNumber = t.TypeOf<typeof InnerNumberItem>;

const SeatStatusItem = t.intersection([
  t.type({
    userId: t.string,
    timestamp: t.string,
    status: t.union([
      t.literal('STATUS_FREE'),
      t.literal('STATUS_REST'),
      t.literal('STATUS_BUSY'),
      t.literal('STATUS_COUNTDOWN'),
      t.literal('STATUS_ARRANGE'),
      t.literal('STATUS_NOT_READY'),
      t.literal('STATUS_OFFLINE'),
    ]),
    logicStatus: t.string,
    logicState: t.string,
    reason: t.string,
    sdkAppId: t.string,
    source: t.string,
    sequence: t.string,

    cursor: t.string,
  }),
  t.partial({ assignCall: t.boolean, assignIM: t.boolean }),
]);

export const adminAPIs = {
  '/tcccadmin/staff/getStaffList': {
    Input: t.partial({
      userId: t.string,
      staffName: t.string,
      roleId: t.string,
      mobile: t.string,
      staffNo: t.string,
      batchUserIds: t.array(t.string),
      skillGroupId: t.string,
      notSkillGroupId: t.string,
      fuzzingKeyWord: t.string,
      pageNum: numberFromString,
      pageSize: numberFromString,
      orderField: t.string,
      orderType: t.string,
      isNeedStatus: t.boolean,
      orderBy: t.string,
      status: t.array(t.number),
      statusRefreshTime: t.union([
        t.undefined,
        t.type({
          from: t.string,
          to: t.string,
        }),
      ]),
      useMobileAcceptType: t.array(UseMobileAccept),
    }),
    Output: t.type({
      total: numberFromString,
      staffList: nullableArray(StaffItem),
    }),
  },
  '/tcccadmin/staff/delete': {
    Input: t.type({
      staffUserId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/getStaffConfig': {
    Input: t.type({
      staffUserId: t.string,
    }),
    Output: t.type({
      canUseMobile: t.boolean,
      useMobileCallOut: t.boolean,
      useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
    }),
  },
  '/tcccadmin/staff/add': {
    Input: t.partial({
      roleId: t.number,
      staffUserId: t.string,
      staffNumber: t.string,
      staffName: t.string,
      staffNickName: t.string,
      skillGroupIds: t.array(t.number),
      useMobileCallOut: t.boolean,
      useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
    }),
    Output: t.type({
      emailSent: t.boolean,
    }),
  },
  '/tcccadmin/staff/update': {
    Input: t.intersection([
      t.type({
        staffUserId: t.string,
        staffName: t.string,
        staffNickName: t.string,
        skillGroupIds: t.array(t.number),
        staffRoleID: t.number,
      }),
      t.partial({
        useMobileCallOut: t.boolean,
        useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
      }),
    ]),

    Output: t.type({}),
  },
  '/tcccadmin/tech/getSkillGroupList': {
    Input: t.partial({
      pageNum: numberFromString,
      pageSize: numberFromString,
      fuzzingKeyWord: t.string,
      skillGroupType: SkillGroupType,
      extraFlag: t.string,
      orderBy: t.array(t.type({ field: t.string, asc: t.boolean })),
      freeOnly: t.boolean,
    }),
    Output: t.type({
      total: numberFromString,
      skillGroupList: nullableArray(SkillGroupItem),
    }),
  },
  '/tcccadmin/staff/getRoleList': {
    Input: t.partial({
      pageNum: numberFromString,
      pageSize: numberFromString,
    }),
    Output: t.type({
      roleList: t.array(
        t.intersection([
          t.type({
            roleId: t.string,
            roleName: t.string,
          }),
          t.partial({
            permissionList: t.array(t.string),
            staffList: t.array(t.string),
            skillGroupPermissionList: t.array(
              t.type({
                permission: t.union([t.literal('*'), t.array(t.string)]),
                skillGroupList: t.array(t.number),
              }),
            ),
          }),
        ]),
      ),
      total: t.string,
    }),
  },
  '/tcccadmin/staff/resetPasswd': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
        userId: t.string,
      }),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/kickOffOrRecoverStaff': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
        userId: t.string,
      }),
      kickOff: booleanFromString0or1,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/getInternalCdr': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
        userId: t.string,
      }),
      startTimestamp: t.number,
      endTimestamp: t.number,
      caller: t.string,
      callee: t.string,
      offset: t.number,
      limit: t.number,
    }),
    Output: t.type({
      total: t.number,
      data: t.union([
        t.null,
        t.array(
          t.type({
            beginTimestamp: t.number,
            endTimestamp: t.union([t.null, t.number]),
            caller: t.string,
            callee: t.string,
          }),
        ),
      ]),
    }),
  },
  '/tcccadmin/tech/getSkillGroupInfo': {
    Input: t.type({
      sdkAppId: t.string,
      skillGroupIdList: t.array(t.string),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/num/getNumberList': {
    Input: t.partial({
      pageNum: numberFromString,
      pageSize: numberFromString,
      servingNumber: t.string,
      type: t.array(t.number),
      groupId: t.string,
      unlessGroupId: t.string,
    }),
    Output: t.type({
      totalCount: numberFromString,
      numList: t.array(TelNumberItem),
    }),
  },
  '/tcccadmin/num/getNumberGroupList': {
    Input: t.partial({
      pageNum: t.string,
      pageSize: t.string,
    }),
    Output: t.type({
      numGroupList: nullableArray(
        t.type({
          groupDesc: t.string,
          groupId: t.string,
          groupName: t.string,
          canCalloutCount: t.string,
          numberCount: numberFromString,
        }),
      ),
      ungroupedNumberCount: numberFromString,
      total: numberFromString,
    }),
  },
  '/tcccadmin/num/delNumberGroup': {
    Input: t.type({
      groupId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/num/updateNumberGroup': {
    Input: t.type({
      groupId: t.string,
      groupName: t.string,
      groupDesc: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/num/addNumberGroup': {
    Input: t.type({
      groupName: t.string,
      groupDesc: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/num/addNumber': {
    Input: t.type({
      groupId: t.string,
      servingNumList: t.array(t.string),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/num/delNumber': {
    Input: t.type({
      groudId: t.string,
      servingNumList: t.array(t.string),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/num/addServingNumber': {
    Input: t.intersection([
      t.type({
        ivrId: t.number,
        remark: t.string,
      }),
      t.partial({
        assocIvrVersion: t.string,
        servingNum: t.string,
      }),
    ]),
    Output: t.type({}),
  },
  '/tcccadmin/num/delServingNumber': {
    Input: t.type({
      servingNum: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/getIvrList': {
    Input: t.intersection([
      t.type({
        type: t.string,
      }),
      t.partial({
        pubState: stringEnum(IVR_STATUS),
        pageNum: numberFromString,
        pageSize: numberFromString,
      }),
    ]),
    Output: t.type({
      total: t.string,
      ivrList: t.array(IvrItem),
    }),
  },
  '/tcccadmin/ivr/updateIvr': {
    Input: t.type({
      ivrId: t.string,
      ivrName: t.string,
      ivrJsonStr: t.string,
      publish: t.string,
      type: stringEnum(IVR_TYPE),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/addIvr': {
    Input: t.type({
      ivrName: t.string,
      ivrJsonStr: t.string,
      publish: t.string,
      type: stringEnum(IVR_TYPE),
    }),
    Output: t.type({}),
  },
  '/tccclogin/account/updateMobile': {
    Input: t.type({
      oldMobile: t.string,
      mobile: t.string,
      smsCaptcha: t.type({ module: t.string, sessionId: t.string, value: t.string }),
      userid: t.string,
      isInstance: t.literal(true), // 表示修改实例绑定手机，只能为true
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/updateVersionIvr': {
    Input: t.type({
      ivrName: t.string,
      ivrId: t.string,
      ivrJsonStr: t.string,
      publish: t.string,
      version: t.string,
      description: t.string,
      type: stringEnum(IVR_TYPE),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/getAllIvrFileList': {
    Input: t.type({
      pageNum: numberFromString,
      pageSize: numberFromString,
    }),
    Output: t.type({
      ivrFileList: t.array(
        t.type({
          customFileName: t.string,
          fileId: t.string,
          fileKey: t.string,
          fileName: t.string,
          fileUrl: t.string,
          reason: t.string,
          status: t.string,
        }),
      ),
      totalCount: numberFromString,
    }),
  },
  '/tcccadmin/ivr/getIvrFileList': {
    Input: t.type({
      status: t.string,
    }),
    Output: t.type({
      ivrFileList: t.array(
        t.type({
          customFileName: t.string,
          fileId: t.string,
          fileKey: t.string,
          fileName: t.string,
          fileUrl: t.string,
          reason: t.string,
          status: t.string,
        }),
      ),
      totalCount: t.string,
    }),
  },
  '/tcccadmin/ivr/uploadIvr': {
    Input: t.type({
      customFileName: t.string,
      file: new t.Type<File>(
        'File',
        (o): o is File => o instanceof File,
        (o, c) => (o instanceof File ? t.success(o) : t.failure(o, c, 'is not an instanceof File')),
        (o) => o,
      ),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/delIvrFile': {
    Input: t.type({
      fileKey: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/getRoutePolicy': {
    Input: t.type({
      type: t.number,
    }),
    Output: t.type({
      routePolicy: t.string,
      usingLastSeat: booleanFromString0or1,
      usingLastCallOutSeat: t.boolean,
      customerRouteUrl: t.string,
    }),
  },
  '/tcccadmin/app/setRoutePolicy': {
    Input: t.partial({
      type: t.number,
      routePolicy: t.string,
      usingLastSeat: booleanFromString0or1,
      usingLastCallOutSeat: t.boolean,
      customerRouteUrl: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/getPhoneNumReflect': {
    Input: t.type({}),
    Output: t.type({
      enabled: t.boolean,
    }),
  },
  '/tcccadmin/app/setPhoneNumReflect': {
    Input: t.intersection([
      t.type({
        mode: t.union([t.literal(2), t.literal(1), t.literal(0)]),
      }),
      t.partial({ enabled: t.boolean, url: t.string }),
      t.union([
        t.type({
          authType: t.literal('basic'),
          authConfig: t.type({
            username: t.string,
            password: t.string,
          }),
        }),
        t.type({
          authType: t.literal('oauth2'),
          authConfig: t.type({
            clientID: t.string,
            clientSecret: t.string,
            tokenURL: t.string,
          }),
        }),
      ]),
    ]),

    Output: t.type({}),
  },
  '/wxwork/getPreAuthCode': {
    Input: t.type({
      suitCategory: t.string,
      redirectUri: t.string,
    }),
    Output: t.type({
      suiteId: t.string,
      state: t.string,
      preAuthCode: t.string,
    }),
  },
  '/wxwork/getKFList': {
    Input: t.type({
      wxAppId: t.string,
    }),
    Output: t.type({
      kfList: t.union([
        t.null,
        t.array(
          t.type({
            openKFId: t.string,
            name: t.string,
          }),
        ),
      ]),
    }),
  },
  '/tcccadmin/app/getWXKFBoundList': {
    Input: t.type({
      wxAppId: t.string,
      pageNum: numberFromString,
      pageSize: numberFromString,
    }),
    Output: t.type({
      boundList: t.union([
        t.null,
        t.array(
          t.type({
            openKFId: t.string,
            ivrId: t.string,
          }),
        ),
      ]),
    }),
  },
  '/tcccadmin/app/addWXKFBound': {
    Input: t.type({
      wxAppId: t.string,
      openKFId: t.string,
      ivrId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/delWXKFBound': {
    Input: t.type({
      wxAppId: t.string,
      openKFId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/editWXKFBound': {
    Input: t.type({
      wxAppId: t.string,
      openKFId: t.string,
      ivrId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/getRestReasonList': {
    Input: t.type({}),
    Output: t.type({
      restReasonList: t.array(
        t.type({
          name: t.string,
          restId: t.string,
          sortId: t.string,
          state: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/blackUser/getList': {
    Input: t.intersection([
      t.type({
        pageNum: t.number,
        pageSize: t.number,
      }),
      t.partial({
        keyworks: t.array(t.string),
        userTypes: t.array(t.number),
        orderBy: t.array(
          t.type({
            field: t.string,
            asc: t.boolean,
          }),
        ),
      }),
    ]),
    Output: t.type({
      blackUserList: nullableArray(BlackUser),
      total: t.number,
    }),
  },
  '/tcccadmin/blackUser/del': {
    Input: t.intersection([
      t.type({
        userType: t.number,
      }),
      t.partial({
        mobile: t.string,
        ids: t.array(t.number),
        id: t.number,
        openId: t.string,
        desc: t.string,
        nickName: t.string,
        servingNum: t.string,
      }),
    ]),
    Output: t.type({}),
  },
  '/tcccadmin/blackUser/add': {
    Input: t.intersection([
      t.type({
        userType: t.number,
      }),
      t.partial({
        mobile: t.string,
        openId: t.string,
        id: t.number,
        nickName: t.string,
        servingNum: t.string,
      }),
    ]),
    Output: t.type({}),
  },
  '/tcccadmin/blackUser/update': {
    Input: t.partial({
      mobile: t.string,
      id: t.number,
      openId: t.string,
      nickName: t.string,
      servingNum: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/setConfig': {
    Input: t.partial({
      useMobileCallOut: t.boolean,
      useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/getConfig': {
    Input: t.type({}),
    Output: t.type({
      canUseMobile: t.boolean,
      useMobileCallOut: t.boolean,
      useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
    }),
  },
  '/tcccadmin/app/getOnHoldAudio': {
    Input: t.type({}),
    Output: t.type({
      audioContent: t.string,
      audioType: t.string,
    }),
  },
  '/tcccadmin/app/setOnHoldAudio': {
    Input: t.type({
      audioType: t.string,
      audioContent: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/getAppInfo': {
    Input: t.type({}),
    Output: AppSettingsC,
  },
  '/tcccadmin/demo/getDemoUserProfile': {
    Input: t.type({}),
    Output: t.type({
      phoneNum: t.string,
      companyName: t.string,
      businessScenario: nullableArray(createEnumType(BusinessScenario)),
      accessMode: t.union([t.literal(0), createEnumType(AccessMode)]),
      isEndUser: t.union([t.literal(0), booleanFromNumber1or2]),
    }),
  },
  '/tcccadmin/app/setAppInfo': {
    Input: AppSettingsC,
    Output: t.type({}),
  },
  '/tcccadmin/pstnLine/queryWhiteListApply': {
    Input: t.type({
      pageIndex: t.number,
      pageSize: t.number,
      order: t.union([t.literal(1), t.literal(2)]),
      callNumber: t.union([t.undefined, t.string]),
    }),
    Output: t.type({
      applyWhiteList: t.array(
        t.type({
          applyId: t.number,
          sdkAppId: t.number,
          callerList: t.string,
          calleeList: t.string,
          description: t.string,
          state: t.union([t.literal(0), t.literal(1), t.literal(2), t.literal(3)]), //   0-提交(待完善)|1-待审核|2-审核通过|3-审核驳回
          lastChecker: t.string,
          lastCheckTime: t.number,
          lastCheckMsg: t.string,
          createTime: t.number,
          modifyTime: t.number,
        }),
      ),
      count: t.number,
    }),
  },
  '/tcccadmin/pstnLine/addWhiteList': {
    Input: t.partial({
      callerList: t.array(t.string),
      calleeList: t.array(t.string),
      description: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/pstnLine/modifyWhiteList': {
    Input: t.partial({
      callerList: t.array(t.string),
      calleeList: t.array(t.string),
      description: t.string,
      applyId: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/pstnLine/delWhiteListApply': {
    Input: t.type({
      applyId: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/pstnLine/queryWhiteList': {
    Input: t.type({
      pageIndex: t.number,
      pageSize: t.number,
      order: t.union([t.literal(1), t.literal(2)]),
      callNumber: t.union([t.undefined, t.string]),
    }),
    Output: t.type({
      whiteList: t.array(
        t.type({
          id: t.number,
          caller: t.string,
          callee: t.string,
          create_at: t.number,
        }),
      ),
      count: t.number,
    }),
  },
  '/tcccadmin/imbot/getBotList': {
    Input: t.type({
      pageNum: numberFromString,
      pageSize: numberFromString,
    }),
    Output: t.type({
      totalCount: numberFromString,
      list: nullableArray(
        t.type({
          botId: t.string,
          name: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/imbot/delBot': {
    Input: t.type({
      botId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/imbot/syncBot': {
    Input: t.type({
      botId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/imbot/addBot': {
    Input: t.type({
      name: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/import': {
    Input: t.type({}),
    Output: t.type({}),
  },
  '/tcccadmin/staff/updateGroupBatch': {
    Input: t.type({
      sdkAppId: t.string,
      skillGroupId: t.string,
      staffList: t.array(StaffItem),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/statisticsInCurveForTel': {
    Input: t.intersection([
      t.type({
        from: t.number,
        to: t.number,
        groupByHour: t.boolean,
        curveType: t.union([
          t.literal('callInCount'),
          t.literal('callInAcceptedCount'),
          t.literal('callOutCount'),
          t.literal('callOutAcceptedCount'),
        ]),
      }),
      t.partial({
        servingNums: nullableArray(t.string),
        skillGroupIds: nullableArray(t.number),
        userIds: nullableArray(t.string),
      }),
    ]),
    Output: t.type({
      data: nullableArray(CurveItem),
    }),
  },
  '/tcccadmin/app/statisticsInTotalForTel': {
    Input: t.intersection([
      t.type({
        from: t.number,
        to: t.number,
      }),
      t.partial({
        servingNums: nullableArray(t.string),
        skillGroupIds: nullableArray(t.number),
        userIds: nullableArray(t.string),
      }),
    ]),
    Output: t.type({
      callInCount: t.number,
      callInTransferToSeatCount: t.number,
      callInAcceptedCount: t.number,
      callInAvgDuration: t.number,
      callInIVRGiveUpCount: t.number,
      callInWaitingSeatGiveUpCount: t.number,
      callOutCount: t.number,
      callOutAcceptedCount: t.number,
      callOutAvgDuration: t.number,
      callInStateList: nullableArray(
        t.type({
          state: t.number,
          count: t.number,
        }),
      ),
      calloutStateList: nullableArray(
        t.type({
          state: t.number,
          count: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/app/statisticsDetailForTelCallIn': {
    Input: t.type({
      from: t.number,
      to: t.number,
      pageSize: t.number,
      pageNumber: t.number,
      groupByHour: t.boolean,
      servingNums: nullableArray(t.string),
      skillGroupIds: nullableArray(t.number),
      userIds: nullableArray(t.string),
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          time: t.string,
          totalCount: t.number,
          transferToSeatCount: t.number,
          acceptedCount: t.number,
          avgDuration: t.number,
          ivrGiveUpCount: t.number,
          avgWaitingTime: t.number,
          waitingSeatGiveUpCount: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/app/statisticsDetailForTelCallOut': {
    Input: t.type({
      from: t.number,
      to: t.number,
      pageSize: t.number,
      pageNumber: t.number,
      groupByHour: t.boolean,
      servingNums: nullableArray(t.string),
      skillGroupIds: nullableArray(t.number),
      userIds: nullableArray(t.string),
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          time: t.string,
          totalCount: t.number,
          acceptedCount: t.number,
          avgDuration: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/app/statisticsInCurveForIM': {
    Input: t.type({
      from: t.number,
      to: t.number,
      groupByHour: t.boolean,
      curveType: t.union([t.literal('totalCount'), t.literal('acceptedCount')]),
    }),
    Output: t.type({
      data: nullableArray(CurveItem),
    }),
  },
  '/tcccadmin/app/statisticsInTotalForIM': {
    Input: t.type({
      from: t.number,
      to: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      transferToSeatCount: t.number,
      acceptedCount: t.number,
      avgDuration: t.number,
      ivrGiveUpCount: t.number,
    }),
  },
  '/tcccadmin/app/statisticsDetailForIM': {
    Input: t.type({
      from: t.number,
      to: t.number,
      pageSize: t.number,
      pageNumber: t.number,
      groupByHour: t.boolean,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          time: t.string,
          totalCount: t.number,
          transferToSeatCount: t.number,
          acceptedCount: t.number,
          avgDuration: t.number,
          ivrGiveUpCount: t.number,
          avgWaitingTime: t.number,
          autoFinishedCount: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/app/statisticsInTotalForAudio': {
    Input: t.type({
      from: t.number,
      to: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      transferToSeatCount: t.number,
      acceptedCount: t.number,
      avgDuration: t.number,
      ivrGiveUpCount: t.number,
      waitingSeatGiveUpCount: t.number,
    }),
  },
  '/tcccadmin/app/statisticsInCurveForAudio': {
    Input: t.type({
      from: t.number,
      to: t.number,
      groupByHour: t.boolean,
      curveType: t.union([t.literal('totalCount'), t.literal('acceptedCount')]),
    }),
    Output: t.type({
      data: nullableArray(CurveItem),
    }),
  },
  '/tcccadmin/app/statisticsDetailForAudio': {
    Input: t.type({
      from: t.number,
      to: t.number,
      pageSize: t.number,
      pageNumber: t.number,
      groupByHour: t.boolean,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          time: t.string,
          totalCount: t.number,
          transferToSeatCount: t.number,
          acceptedCount: t.number,
          avgDuration: t.number,
          ivrGiveUpCount: t.number,
          waitingSeatGiveUpCount: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/app/statisticsInTotalForSeat': {
    Input: t.type({
      from: t.number,
      to: t.number,
      pageSize: t.number,
      pageNumber: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          time: t.string,
          avgOnlineTime: t.number,
          avgIdleTime: t.number,
          avgBusyTime: t.number,
          avgRestTime: t.number,
          avgArrangeTime: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/ivr/getIvrInfo': {
    Input: t.type({
      type: stringEnum(IVR_TYPE),
      ivrId: t.string,
    }),
    Output: t.partial({
      ivrId: t.string,
      ivrJsonStr: t.string,
      ivrName: t.string,
      mtime: t.string,
      numbers: nullableArray(t.string),
      ivrVersionList: nullableArray(
        t.type({
          description: t.string,
          ivrJsonStr: t.string,
          ivrVersion: t.string,
          mtime: t.string,
          numbers: nullableArray(t.string),
          pubState: stringEnum(IVR_STATUS),
        }),
      ),
      pubState: stringEnum(IVR_STATUS),
      type: stringEnum(IVR_TYPE),
    }),
  },
  '/tcccadmin/ivr/delIvr': {
    Input: t.partial({
      ivrId: t.string,
      version: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/chat/getIMCDR': {
    Input: t.partial({
      fromTime: t.number,
      toTime: t.number,
      pageNumber: t.number,
      pageSize: t.number,
      sessionIds: t.array(t.string),
      searchPhrases: t.array(t.string),
      staffNameOrNickName: t.string,
      skillGroupIds: t.array(t.number),
      staffNumber: t.string,
      staffUserId: t.string,
      conversationType: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          timestamp: t.number,
          source: t.number,
          userId: t.string,
          userNickName: t.string,
          endStatus: t.number,
          satisfaction: t.string,
          waitingTime: t.number,
          duration: t.number,
          sessionId: t.string,
          staffName: t.string,
          staffNumber: t.string,
          staffUserId: t.string,
          skillGroupName: t.string,
          docCount: t.number,
          conversationType: t.number,
          channelAgentID: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/tech/addSkillGroup': {
    Input: t.type({
      instanceId: t.string,
      maxConcurrency: t.string,
      routePolicy: t.number,
      servingNum: t.number,
      skillGroupName: t.string,
      skillGroupType: t.string,
      usingLastSeat: t.number,
    }),
    Output: t.type({
      skillGroupID: t.string,
    }),
  },
  '/tcccadmin/tech/updateSkillGroup': {
    Input: t.type({
      skillGroupId: t.string,
      extraFlag: t.string,
      instanceId: t.string,
      maxConcurrency: t.string,
      routePolicy: t.number,
      servingNum: t.number,
      skillGroupName: t.string,
      skillGroupType: t.string,
      usingLastSeat: t.number,
      ctime: t.string,
      mtime: t.string,
      defaultType: t.string,
      sdkAppId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/tech/delSkillGroup': {
    Input: t.type({
      skillGroupId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/tech/getSkillGroupStat': {
    Input: t.partial({
      fromTime: t.number,
      toTime: t.number,
      pageNumber: t.number,
      pageSize: t.number,
      skillGroupType: SkillGroupType,
    }),
    Output: t.type({
      total: t.number,
      data: nullableArray(
        t.type({
          skillGroupId: t.number,
          skillGroupName: t.string,
          skillGroupType: SkillGroupType,
          freeStaffCnt: t.number,
          busyStaffCnt: t.number,
          notReadyStaffCnt: t.number,
          restStaffCnt: t.number,
          servedCnt: t.number,
          waitingCnt: t.number,
          maxWaitingTime: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/cdr/getTelCdr': {
    Input: t.partial({
      pageNum: t.string,
      pageSize: t.string,
      sessionIds: t.array(t.string),
      caller: t.string,
      callee: t.string,
      groupIds: t.array(t.string),
      startTimeStamp: t.string,
      endTimeStamp: t.string,
      callType: t.string,
      direction: t.string,
      type: t.string,
      remark: t.string,
      state: t.array(PstnEndStatus),
      hangupSide: t.array(HungUpSideType),
      seat_types: t.array(t.string),
      orderBy: t.array(
        t.type({
          field: t.union([t.literal('timestamp'), t.literal('duration')]),
          asc: t.boolean,
        }),
      ),
      staff: t.partial({
        userId: t.string,
        staffName: t.string,
        staffNo: t.string,
      }),
    }),
    Output: t.type({
      total: t.string,
      cdrTelList: nullableArray(
        t.partial({
          callTime: t.string,
          direction: t.string,
          caller: t.string,
          callee: t.string,
          protectedCaller: t.string,
          protectedCallee: t.string,
          remark: t.string,
          callEndStatus: t.string,
          duration: t.string,
          groupId: t.string,
          staff: t.partial({
            mobile: t.string,
            nickName: t.string,
            sdkAppId: t.string,
            staffNo: t.string,
            userId: t.string,
          }),
          postIVRKeyPressed: t.union([
            nullableArray(
              t.type({
                key: t.string,
                label: t.string,
              }),
            ),
            t.null,
          ]),
          hangupSide: t.union([
            t.literal('seat'),
            t.literal('user'),
            t.literal('system'),
            t.literal('admin'),
            t.undefined,
          ]),
          asrUrl: t.string,
          recordUrl: t.string,
          ringTimestamp: t.string,
          endedTimestamp: t.string,
          ivrDuration: t.string,
          ivrKeyPressed: t.string,
          endStatusText: t.string,
          uid: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/staff/setRestReason': {
    Input: t.type({
      restReason: t.partial({
        restId: t.union([t.string, t.undefined]),
        sortId: t.union([t.string, t.undefined]),
        name: t.union([t.string, t.undefined]),
        state: t.union([t.string, t.undefined]),
      }),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/delRestReason': {
    Input: t.type({
      restId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/setPriority': {
    Input: t.type({
      skillId: t.string,
      seatsInfo: t.array(
        t.type({
          seat: t.string,
          priority: t.union([t.literal('1'), t.literal('2'), t.literal('3'), t.literal('4'), t.literal('5')]),
        }),
      ),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/cdr/commitExportTask': {
    Input: t.type({
      fromTime: t.string,
      toTime: t.string,
      fields: t.array(t.string),
      type: t.union([t.literal('tel'), t.literal('im'), t.literal('voipCallIn'), t.literal('videoCallIn')]),
      conditions: t.array(
        t.type({
          fieldName: t.string,
          operator: t.union([t.literal('like'), t.literal('='), t.literal('in')]),
          value: t.union([t.string, t.array(t.string), t.undefined]),
        }),
      ),
    }),
    Output: t.type({
      taskId: t.string,
    }),
  },
  '/tcccadmin/cdr/queryExportTask': {
    Input: t.type({
      sdkAppId: t.string,
      taskId: t.string,
    }),
    Output: t.type({
      progress: t.string,
      filePath: t.string,
      detail: t.string,
      status: t.string,
    }),
  },
  '/tcccadmin/chat/queryUserChatHistoryTask': {
    Input: t.partial({
      direct: t.string,
      size: t.number,
      userId: t.string,
      sessionId: t.string,
      userName: t.string,
      roomId: t.string,
    }),
    Output: t.type({
      totalCount: t.number,
      details: nullableArray(
        t.type({
          nickName: t.string,
          seatName: t.string,
          type: t.string,
        }),
      ),
    }),
  },
  '/wechat/getPreAuthCode': {
    Input: t.type({}),
    Output: t.type({
      preAuthCode: t.string,
      componentAppId: t.string,
    }),
  },
  '/tcccadmin/staff/monitor': {
    Input: t.partial({
      staff: t.type({
        sdkAppId: t.string,
        userId: t.string,
      }),
      staffFuzzySearch: t.string,
      staffStatus: t.array(
        t.union([
          t.literal(0),
          t.literal(100),
          t.literal(200),
          t.literal(300),
          t.literal(400),
          t.literal(500),
          t.literal(700),
        ]),
      ),
      skillGroupId: t.number,
      skillGroupType: SkillGroupType,
      fromTime: t.number,
      toTime: t.number,
      pageSize: t.number,
      pageNumber: t.number,
    }),
    Output: t.type({
      total: numberFromString,
      data: nullableArray(
        t.type({
          staffId: t.string,
          staffNumber: t.string,
          staffName: t.string,
          skillGroupTypes: t.union([t.undefined, nullableArray(SkillGroupType)]),
          skillGroupNames: t.union([t.undefined, nullableArray(t.string)]),
          staffStatus: t.union([
            t.literal(0),
            t.literal(100),
            t.literal(200),
            t.literal(300),
            t.literal(400),
            t.literal(500),
            t.literal(700),
          ]),
          idleTime: t.union([t.undefined, t.number]),
          restTime: t.number,
          notReadyTime: t.number,
          servingCnt: t.number,
          servedCnt: t.number,
        }),
      ),
    }),
  },

  '/tcccadmin/selfservice/add': {
    Input: t.type({
      name: t.string,
      assocIvrId: t.number,
      assocIvrVersion: t.string,
      type: createEnumType(SelfServiceType),
      status: createEnumType(SelfServiceStatus),
      source: createEnumType(SelfServiceSource),
      inputParam: nullableArray(
        t.type({
          key: t.string,
          value: t.string,
        }),
      ),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/selfservice/update': {
    Input: t.type({
      name: t.string,
      assocIvrId: t.number,
      assocIvrVersion: t.string,
      type: createEnumType(SelfServiceType),
      status: createEnumType(SelfServiceStatus),
      inputParam: nullableArray(
        t.type({
          key: t.string,
          value: t.string,
        }),
      ),
    }),
    Output: t.type({
      total: t.number,
      selfServiceList: nullableArray(
        t.type({
          id: t.number,
          name: t.string,
          assocIvrId: t.number,
          assocIvrVersion: t.string,
          type: createEnumType(SelfServiceType),
          status: createEnumType(SelfServiceStatus),
          inputParam: nullableArray(
            t.type({
              key: t.string,
              value: t.string,
            }),
          ),
        }),
      ),
    }),
  },
  '/tcccadmin/selfservice/del': {
    Input: t.type({
      id: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/selfservice/getList': {
    Input: t.partial({
      pageNum: t.number,
      pageSize: t.number,
      status: createEnumType(SelfServiceStatus),
      source: createEnumType(SelfServiceSource),
    }),
    Output: t.type({
      total: t.number,
      selfServiceList: nullableArray(
        t.type({
          id: t.number,
          name: t.string,
          assocIvrId: t.number,
          assocIvrVersion: t.string,
          type: createEnumType(SelfServiceType),
          status: createEnumType(SelfServiceStatus),
          inputParam: nullableArray(
            t.type({
              key: t.string,
              value: t.string,
            }),
          ),
        }),
      ),
    }),
  },
  '/tcccadmin/app/getWebApp': {
    Input: t.partial({
      pageNum: t.string,
      pageSize: t.string,
      webAppType: t.union([t.literal('1'), t.literal('2'), t.literal('3')]),
    }),
    Output: t.type({
      msg: t.string,
      total: t.string,
      appList: nullableArray(
        t.intersection([
          t.type({
            sdkAppId: t.string,
            webAppId: t.string,
            webAppName: t.string,
            satisfactionId: t.string,
          }),
          t.partial({
            assocIvrId: t.string,
            ivrId: t.string,
            skillGroupId: t.string,
          }),
        ]),
      ),
    }),
  },
  '/tcccadmin/app/addWebApp': {
    Input: t.partial({
      webAppName: t.string,
      webAppType: t.union([t.literal('1'), t.literal('2'), t.literal('3')]),
      ivrId: t.number,
      skillGroupId: t.number,
      satisfactionId: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/updateWebAppIvr': {
    Input: t.partial({
      webAppId: t.string,
      webAppName: t.string,
      webAppType: t.union([t.literal('1'), t.literal('2'), t.literal('3')]),
      ivrId: t.number,
      SatisfactionId: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/delWebApp': {
    Input: t.type({
      webAppId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/bindWebAppIvr': {
    Input: t.intersection([
      t.type({
        webAppId: t.string,
      }),
      t.partial({
        ivrId: t.string,
        skillGroupId: t.string,
        satisfactionId: t.string,
      }),
    ]),
    Output: t.type({}),
  },
  '/tcccadmin/app/statisticsInTotalForVideo': {
    Input: t.type({
      from: t.number,
      to: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      acceptedCount: t.number,
      avgDuration: t.number,
      waitingSeatGiveUpCount: t.number,
    }),
  },
  '/tcccadmin/app/statisticsInCurveForVideo': {
    Input: t.type({
      from: t.number,
      to: t.number,
      groupByHour: t.boolean,
      curveType: t.union([t.literal('totalCount'), t.literal('acceptedCount')]),
    }),
    Output: t.type({
      data: nullableArray(CurveItem),
    }),
  },
  '/tcccadmin/app/statisticsDetailForVideo': {
    Input: t.type({
      from: t.number,
      to: t.number,
      pageSize: t.number,
      pageNumber: t.number,
      groupByHour: t.boolean,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          time: t.string,
          totalCount: t.number,
          acceptedCount: t.number,
          avgDuration: t.number,
          waitingSeatGiveUpCount: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/ivr/getLocationList': {
    Input: t.type({}),
    Output: t.type({
      level1: nullableArray(t.string),
    }),
  },
  '/tcccadmin/staff/setAssignAccept': {
    Input: t.type({
      assignCall: t.boolean,
      assignIM: t.boolean,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/getAssignAccept': {
    Input: t.type({}),
    Output: t.type({
      existCall: t.boolean,
      assignCall: t.boolean,
      existIM: t.boolean,
      assignIM: t.boolean,
    }),
  },
  '/tcccadmin/app/setCommKV': {
    Input: t.type({
      key: t.string,
      value: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/getCommKV': {
    Input: t.type({
      key: t.string,
    }),
    Output: t.type({
      value: t.string,
    }),
  },
  '/tcccadmin/app/getChannelQRCode': {
    Input: t.intersection([
      t.type({
        type: t.number,
        page: t.string,
        qrCodeEnvVersion: t.union([t.literal('release'), t.literal('trial'), t.literal('develop')]),
      }),
      t.union([
        t.type({
          channelId: t.string,
        }),
        t.intersection([
          t.type({
            ivrId: t.number,
          }),
          t.partial({
            ivrVersion: t.string,
          }),
        ]),
      ]),
    ]),
    Output: t.type({
      qrCodeLink: t.string,
      validDuration: t.number, // seconds
    }),
  },
  '/tcccadmin/stat/seatStatusTrace': {
    Input: t.intersection([
      t.type({
        beginTime: t.number,
        endTime: t.number,
      }),
      t.partial({
        size: t.number,
        cursor: t.string,
        order: t.union([t.literal(1), t.literal(2)]),
        skillGroupIDs: t.array(t.number),
        userIDs: t.array(t.string),
      }),
    ]),
    Output: t.type({
      total: t.number,
      seatStatusList: nullableArray(SeatStatusItem),
      seatPrevStatusList: nullableArray(SeatStatusItem),
    }),
  },
  '/tcccadmin/stat/seatOnlineList': {
    Input: t.intersection([
      t.type({
        beginTime: t.number,
        endTime: t.number,
      }),
      t.partial({
        skillGroupIDs: t.array(t.number),
        userIDs: t.array(t.string),
      }),
    ]),
    Output: t.type({
      seatList: nullableArray(
        t.type({
          email: t.string,
          nickName: t.string,
          staffName: t.string,
          groupIDs: t.string,
          staffNo: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/app/statisticsServingNumTotalForTel': {
    Input: t.intersection([
      t.type({
        from: t.number,
        to: t.number,
      }),
      t.partial({
        servingNums: nullableArray(t.string),
      }),
    ]),
    Output: t.type({
      servingNumCallInStateList: nullableArray(
        t.type({
          state: t.number,
          count: t.number,
          servingNum: t.string,
        }),
      ),
      servingNumCallOutStateList: nullableArray(
        t.type({
          state: t.number,
          count: t.number,
          servingNum: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/app/getIMAgentList': {
    Input: t.type({
      pageSize: t.number,
      pageNumber: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          imSdkAppId: t.number,
          imAgentUserId: t.string,
          imAgentUserName: t.string,
          imAgentAvatarURL: t.string,
          ivrId: t.number,
          satisfactionId: t.number,
          useUIKit: t.boolean,
        }),
      ),
    }),
  },
  '/tcccadmin/app/getIMAgentListForContactUser': {
    Input: t.type({}),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          imSdkAppId: t.number,
          imAgentUserId: t.string,
          imAgentUserName: t.string,
          imAgentAvatarURL: t.string,
          ivrId: t.number,
          satisfactionId: t.number,
          useUIKit: t.boolean,
        }),
      ),
    }),
  },
  '/tcccadmin/app/updateIMAgent': {
    Input: t.type({
      imSdkAppId: t.number,
      imAgentUserId: t.string,
      ivrId: t.number,
      satisfactionId: t.number,
      useUIKit: t.boolean,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/delIMAgent': {
    Input: t.type({
      imSdkAppId: t.number,
      imAgentUserId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/extension/getRegInfo': {
    Input: t.type({
      extensionId: t.string,
    }),
    Output: t.type({
      extensionDomain: t.string,
      extensionId: t.string,
      outboundProxy: t.string,
      outboundProxyBak: t.string,
      password: t.string,
      transport: t.string,
      granted: t.boolean,
      userAgent: t.string,
    }),
  },
  '/tcccadmin/extension/resetPassword': {
    Input: t.type({
      extensionId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/extension/getList': {
    Input: t.partial({
      fuzzingKeyWord: t.string,
      batchExtensionIds: t.array(t.string),
      extensionName: t.string,
      skillGroupId: t.string,
      notSkillGroupId: t.string,
      pageSize: numberFromString,
      pageNum: numberFromString,
      orderField: t.string,
      orderType: t.string,
      isNeedStatus: t.boolean,
      relation: t.string,
      relationName: t.string,
    }),
    Output: t.type({
      total: numberFromString,
      extensionList: nullableArray(ExtensionItem),
    }),
  },
  '/tcccadmin/extension/delete': {
    Input: t.type({
      extensionId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/extension/add': {
    Input: t.partial({
      extensionId: t.string,
      extensionName: t.string,
      skillGroupIds: t.array(t.number),
      relation: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/extension/update': {
    Input: t.intersection([
      t.type({
        extensionId: t.string,
        extensionName: t.string,
      }),
      t.partial({
        skillGroupIds: t.array(t.number),
        relation: t.string,
      }),
    ]),
    Output: t.type({}),
  },
  '/tcccadmin/extension/updateGroupBatch': {
    Input: t.type({
      skillGroupId: t.string,
      extensionList: t.array(ExtensionItem),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/sourcealias/getList': {
    Input: t.partial({
      aliasType: t.number,
      pageSize: t.number,
      pageNum: t.number,
      fuzzingKeyWord: t.string,
    }),
    Output: t.type({
      total: t.number,
      sourceAliasList: nullableArray(InnerNumberItem),
    }),
  },
  '/tcccadmin/sourcealias/add': {
    Input: t.partial({
      aliasType: t.number,
      alias: t.string,
      origin: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/sourcealias/update': {
    Input: t.partial({
      aliasType: t.number,
      alias: t.string,
      origin: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/sourcealias/delete': {
    Input: t.type({
      alias: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/app/statisticsDetailForSeatSatisfied': {
    Input: t.intersection([
      t.type({
        from: t.number,
        to: t.number,
        callType: t.union([t.literal('callout'), t.literal('callin')]),
      }),
      t.partial({
        skillGroupIds: t.array(t.number),
        userIds: t.array(t.string),
        servingNums: t.array(t.string),
      }),
    ]),
    Output: t.type({
      totalCount: numberFromString,
      data: nullableArray(
        t.type({
          callCount: numberFromString,
          satisfiedCount: numberFromString,
          enterSatisfiedCount: numberFromString,
          seat: t.string,
          staffName: t.string,
          staffNo: t.string,
          groupName: t.string,
          detailForSeatSatisfied: nullableArray(
            t.type({
              pressKey: t.string,
              label: t.string,
              pressKeyCount: t.number,
            }),
          ),
        }),
      ),
    }),
  },
  '/tcccadmin/num/getLineUnavailableWarning': {
    Input: t.type({}),
    Output: t.type({
      stopNumberList: nullableArray(
        t.type({
          servingNum: t.string,
          stopType: t.number,
        }),
      ),
      unavailable400CallInPkgWarning: t.union([t.literal(1), t.literal(0)]),
      unavailableLandLineCallOutPkgWarning: t.union([t.literal(1), t.literal(0)]),
      unavailableAsrOfflinePkgWarning: t.union([t.undefined, t.literal(0), t.literal(1)]),
      unavailableAsrRealtimePkgWarning: t.union([t.literal(1), t.literal(0), t.undefined]),
      unavailableSeatPkgWarning: t.union([t.undefined, t.literal(0), t.literal(1)]),
      unavailableSeatLicenseWarning: t.union([t.undefined, t.literal(0), t.literal(1)]),
    }),
  },
  '/tcccadmin/staff/addRole': {
    Input: t.type({
      roleName: t.string,
      permissionList: t.array(t.string),
      staffList: t.array(t.string),
      skillGroupPermissionList: t.array(
        t.type({
          permission: t.union([t.literal('*'), t.array(t.string)]),
          skillGroupList: t.array(t.number),
        }),
      ),
    }),
    Output: t.type({
      roleId: t.string,
    }),
  },
  '/tcccadmin/staff/modifyRole': {
    Input: t.type({
      roleId: t.string,
      roleName: t.string,
      permissionList: t.array(t.string),
      staffList: t.array(t.string),
      skillGroupPermissionList: t.array(
        t.type({
          permission: t.union([t.literal('*'), t.array(t.string)]),
          skillGroupList: t.array(t.number),
        }),
      ),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/delRole': {
    Input: t.type({
      roleId: t.string,
    }),
    Output: t.type({}),
  },
  '/tccclogin/account/bindMobile': {
    Input: t.type({
      mobile: t.string,
      captcha: t.type({
        value: t.string,
        sessionId: t.string,
        module: t.string,
      }),
      isInstance: t.boolean,
    }),
    Output: t.type({}),
  },
};
